import React, {useEffect} from 'react';
import {Box} from '@material-ui/core';

import BlogItem from './BlogItem';

import {blogsDataForBlogsPage} from '../../utilities/blogsData/blogsData';

import './Blogs.css';

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box style={{backgroundColor: '#f4f9fc'}}>
      <Box
        style={{
          width: '82%',
          margin: 'auto',
        }}
      >
        <Box style={{paddingTop: '1rem', paddingBottom: '3rem'}}>
          <Box className='working-process-cards-container'>
            {blogsDataForBlogsPage.map(blog => (
              <Box key={blog.blog_id}>
                <BlogItem
                  blogID={blog.blog_id}
                  blogAuthor={blog.blog_author}
                  blogDate={blog.blog_date}
                  blogCommentsCount={blog.blog_comments_count}
                  blogImage={blog.blog_image}
                  blogTitle={blog.blog_title}
                  blogText={blog.blog_text}
                />
              </Box>
            ))}
            {/* <BlogItem
              blogAuthor='Soamlia'
              blogDate='05 Aug 2019'
              blogCommentsCount='03'
              blogImage='images/ourBlogs/blog1.jpg'
              blogTitle='Monthly Web Development To Update React Hooks Cons'
              blogText='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque'
            />
            <BlogItem
              blogAuthor='Soamlia'
              blogDate='05 Aug 2019'
              blogCommentsCount='03'
              blogImage='images/ourBlogs/blog2.jpg'
              blogTitle='Comparison Between WordPress & October CMS'
              blogText='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque'
            />
            <BlogItem
              blogAuthor='Soamlia'
              blogDate='05 Aug 2019'
              blogCommentsCount='03'
              blogImage='images/ourBlogs/blog3.jpg'
              blogTitle='Exploring Latest Web Design Together With Theme'
              blogText='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque'
            />
            <BlogItem
              blogAuthor='Soamlia'
              blogDate='05 Aug 2019'
              blogCommentsCount='03'
              blogImage='images/ourBlogs/blog1.jpg'
              blogTitle='Monthly Web Development To Update React Hooks Cons'
              blogText='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque'
            />
            <BlogItem
              blogAuthor='Soamlia'
              blogDate='05 Aug 2019'
              blogCommentsCount='03'
              blogImage='images/ourBlogs/blog2.jpg'
              blogTitle='Comparison Between WordPress & October CMS'
              blogText='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque'
            />
            <BlogItem
              blogAuthor='Soamlia'
              blogDate='05 Aug 2019'
              blogCommentsCount='03'
              blogImage='images/ourBlogs/blog3.jpg'
              blogTitle='Exploring Latest Web Design Together With Theme'
              blogText='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque'
            />
            <BlogItem
              blogAuthor='Soamlia'
              blogDate='05 Aug 2019'
              blogCommentsCount='03'
              blogImage='images/ourBlogs/blog1.jpg'
              blogTitle='Monthly Web Development To Update React Hooks Cons'
              blogText='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque'
            />
            <BlogItem
              blogAuthor='Soamlia'
              blogDate='05 Aug 2019'
              blogCommentsCount='03'
              blogImage='images/ourBlogs/blog2.jpg'
              blogTitle='Comparison Between WordPress & October CMS'
              blogText='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque'
            />
            <BlogItem
              blogAuthor='Soamlia'
              blogDate='05 Aug 2019'
              blogCommentsCount='03'
              blogImage='images/ourBlogs/blog3.jpg'
              blogTitle='Exploring Latest Web Design Together With Theme'
              blogText='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque'
            /> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Blogs;
