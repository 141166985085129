import React from 'react';
import {Box, Typography} from '@material-ui/core';

import WorkingProcessItem from './WorkingProcessItem';

import './WorkingProcess.css';

const WorkingProcess = () => {
  return (
    <Box id='workingProcessSection' className='working-process-container'>
      <Box className='working-process-inner-container'>
        <Box>
          <Box className='working-process-text-container'>
            <span className='working-process-text-line'></span>
            <Typography component='span' className='working-process-text'>
              WORKING PROCESS
            </Typography>
            <span className='working-process-text-line'></span>
          </Box>
          <Box className='working-process-large-text-container'>
            <Typography component='h1' className='working-process-large-text'>
              How We Work
            </Typography>
          </Box>
          <Box className='working-process-cards-container'>
            <WorkingProcessItem
              processIcon='fas fa-eye'
              processTitle='Ideate'
              processBody='To analyze the requirement through in-depth research with the help of industry standard tools.
'
            />
            <WorkingProcessItem
              processIcon='fas fa-pen-nib'
              processTitle='Sketch'
              processBody='To draft the design that is related to the research in order to check the feasibility.
'
            />
            <WorkingProcessItem
              processIcon='fas fa-palette'
              processTitle='Design'
              processBody='After completing feasibility test, a final design is prepared by using smart tools.
'
            />
            <WorkingProcessItem
              processIcon='fas fa-rocket'
              processTitle='Develop'
              processBody='Now, our development team starts developing the solution according to design.
'
            />
            <WorkingProcessItem
              processIcon='fas fa-vials'
              processTitle='Test'
              processBody='In testing phase, we test every component to make sure that our solution fits the requirement.
'
            />
            <WorkingProcessItem
              processIcon='fas fa-vr-cardboard'
              processTitle='Evolve'
              processBody='We deliver the solution and assist our clients to control and maintain the solution.
'
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkingProcess;
