import React from 'react';
import {Box, Typography} from '@material-ui/core';

const DomainItem = ({domainImage, domainText}) => {
  return (
    <Box className='domains-card-container'>
      <Box>
        <i className={`${domainImage} domains-card-icon`}></i>
      </Box>
      <Box>
        <Typography className='domains-card-text'>{domainText}</Typography>
      </Box>
    </Box>
  );
};

export default DomainItem;
