import React from 'react';
import './Gallery.css';

const IMAGES =
  [{
    src: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG-20181102-WA0002.jpg",
    thumbnail: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG-20181102-WA0002.jpg",
  },
    {
      src: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20190720_223840_059.jpg",
      thumbnail: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20190720_223840_059.jpg",
    },
    {
      src: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20190108_182220.jpg",
      thumbnail: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20190108_182220.jpg",
    },
    {
      src: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20200115_185733.jpg",
      thumbnail: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20200115_185733.jpg",
    },
    {
      src: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG-20190503-WA0010.jpg",
      thumbnail: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG-20190503-WA0010.jpg",
    },
    {
      src: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG-20190720-WA0198.jpg",
      thumbnail: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG-20190720-WA0198.jpg",
    },
    {
      src: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20181227_162610.jpg",
      thumbnail: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20181227_162610.jpg",
    },
    {
      src: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20181228_232341.jpg",
      thumbnail: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20181228_232341.jpg",
    },
    {
      src: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20190406_122609.jpg",
      thumbnail: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20190406_122609.jpg",
    },
    {
      src: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20190722_232901_816.jpg",
      thumbnail: "https://wemotive-photos.s3.ap-south-1.amazonaws.com/IMG_20190722_232901_816.jpg",
    }
  ]

const stylesmall = () => {
  return ({
    width: '250px',
    height: '200px',
    objectFit: 'fill',
    borderRadius: '15px'
  })
}

const Clients = () => {


  return (
    <div></div>
  );
};

export default Clients;
