import React from 'react';
import {Box, Typography} from '@material-ui/core';

const TestimonialItem = ({
                           testimonialText,
                           testimonialUserName,
                           testimonialUserProfile,
                         }) => {
  return (
    <Box
      className='our-testimonials-testimonial-container fix-me'
    >
      <Box>
        <Typography
          className='our-testimonials-testimonial-text'
          style={{textAlign: 'center'}}
        >
          <i
            className='fa fa-quote-left'
            style={{marginRight: '1rem', fontSize: '1rem', color: '#f90'}}
          />
          {testimonialText}
          <i
            className='fa fa-quote-right'
            style={{marginLeft: '1rem', fontSize: '1rem', color: '#f90'}}
          />
        </Typography>
      </Box>

      <Box className='our-testimonials-testimonial-user-profile-container'>
        <Box style={{textAlign: 'center'}}>
          <Typography className='our-testimonials-testimonial-user-name-text'>
            {testimonialUserName}
          </Typography>
          <Typography className='our-testimonials-testimonial-user-profile-text'>
            {testimonialUserProfile}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TestimonialItem;
