import React from 'react';
import {Box, Grid, Typography} from '@material-ui/core';

import aboutImage from './img/about.jpg';

import './AboutUs.css';

const AboutUs = () => {
  return (
    <Box>
      <Box className='about-container' id='aboutSection'>
        <Box className='about-inner-container'>
          <Box className='about-left-image-container'>
            <Box>
              <img src={aboutImage} alt='about' className='about-left-image'/>
            </Box>
          </Box>
          <Box className='about-right-text-container'>
            <Box className='about-aboutus-text-container'>
              <span className='about-aboutus-line'></span>
              <Typography component='span' className='about-aboutus-text'>
                ABOUT US
              </Typography>
              <span className='about-aboutus-line'></span>
            </Box>
            <Box className='about-why-text-container'>
              <Typography className='about-why-text'>
                Why Most People Choose Our Business Solutions
              </Typography>
            </Box>
            <Box className='about-why-bellow-text-container'>
              <Typography className='about-why-bellow-text'>
                Via Simplex believes in closely working with the customers to build
                pragmatic products and services, helping them implement their
                ideas and scaling them into viable businesses. Our technology
                expertise helps our customers craft solutions for critical
                technological challenges. We believe in forging ahead together
                with our customers, sharing their vision and realizing their
                dreams.
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="h6">
                    Reliable
                  </Typography>
                  <Typography variant="body1" className='about-why-bellow-text'>
                    We believe in building and maintaining long term
                    relationships with all our clients.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="h6">
                    Solutions
                  </Typography>
                  <Typography variant="body1" className='about-why-bellow-text'>
                    We endeavor to offer the best solutions in order to acquire
                    customers’ maximum satisfaction. We are seasoned in offering
                    effective software development solutions.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="h6">
                    Experience
                  </Typography>
                  <Typography variant="body1" className='about-why-bellow-text'>
                    We are seasoned in tackling problems in software
                    development. Our experts handle your assigned projects
                    professionally and responsibly.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="h6">
                    Affordable
                  </Typography>
                  <Typography variant="body1" className='about-why-bellow-text'>
                    We provide effective and affordable web and mobile
                    development services to numerous large as well as medium
                    entrepreneurs.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
