import React from 'react';
import {Box, Typography} from '@material-ui/core';

import SwiperCore, {Autoplay, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import TestimonialItem from './TestimonialItem';

import user1 from './img/user1.png';
import user2 from './img/user2.png';
import user3 from './img/user3.png';

import 'swiper/swiper-bundle.css';

import './OurTestimonials.css';

SwiperCore.use([Pagination, Autoplay]);

const OurTestimonials = () => {
  const params = {
    slidesPerView: 1,
    spaceBetween: 50,
    pagination: {clickable: true},
    autoplay: {
      delay: 55000,
      disableOnInteraction: false,
    },
    breakpoints: {
      1300: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      1000: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      730: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };

  return (
    <Box className='our-testimonials-container'>
      <Box className='our-testimonials-inner-container'>
        <Box>
          <Box className='our-testimonials-text-container'>
            <span className='our-testimonials-line'></span>
            <Typography component='span' className='our-testimonials-text'>
              TESTIMONIALS
            </Typography>
            <span className='our-testimonials-line'></span>
          </Box>
        </Box>

        <Box
          className='our-testimonials-swiper-container'
          style={{marginTop: '3rem'}}
        >
          <Swiper {...params} style={{paddingBottom: '2.5rem'}}>
            <SwiperSlide>
              <TestimonialItem
                testimonialText='Wemotive have worked with us as a Partner, on multiple project assignment. I found Wemotive a very professional organization. They have a very good expertise in Web & Mobile Development. The team is very motivated, pro-active and always make right recommendations wherever required. Wemotive shall remain our partner of choice for the Web & Mobile Development assignments.'
                testimonialRating={4}
                testimonialUserPhoto={user1}
                testimonialUserName='Atul P Deshpande'
                testimonialUserProfile='Founder & CEO (Shyena Tech Yarns)'
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                testimonialText='We were looking for a seasoned, skilled team of developers that participate as partners rather than vendors in the product development process. WEMotive was uniquely qualified, and went above and beyond the ask, delivering a leading-edge yet reliable solution.'
                testimonialRating={4}
                testimonialUserPhoto={user2}
                testimonialUserName='Nakul Datar'
                testimonialUserProfile='Founder (ViaSimplex)'
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                testimonialText='Since 2014, Krixi Corp. has been working with the founders of WEMotive. They were first colleagues and then, business partners. The team’s professionalism, technical expertise and business acumen are at par with the best. We strongly recommend WEMotive’s team and services to you!'
                testimonialRating={3}
                testimonialUserPhoto={user3}
                testimonialUserName='Sandeep J. Agate'
                testimonialUserProfile='Founder & CEO (Krixi Corp.)'
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                testimonialText='99.9999 reliability, 100% ownership, and lots of humility are found in every deliverable of Wemotive.'
                testimonialRating={4}
                testimonialUserPhoto={user1}
                testimonialUserName='Navdeep Agarwal'
                testimonialUserProfile='Founder (DataOrc)'
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                testimonialText='We are so lucky to have such trustworthy partners in Wemotive. Transparency and honesty define them as much as their technical capabilities and solutioning do. Extremely prompt and professional in their approach, it has been a pleasure to working with the Team. The application they helped us build is helping us redefine content localisation supply, making content more accessible and the Internet more inclusive. Highly recommend Wemotive to anyone looking for an hassle free IT solution.'
                testimonialRating={2}
                testimonialUserPhoto={user2}
                testimonialUserName='Sandeep Nulkar'
                testimonialUserProfile='Co-founder & CEO (BITS Language Technologies)'
              />
            </SwiperSlide>
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
};

export default OurTestimonials;
