export const jobsDataForCareersPage = [
  {
    job_id: '1',
    job_label: 'You will be architecting and building performant mobile apps on both the iOS and Android platforms.',
    job_title: 'React Native Developer',
    job_description: `We are looking for a React Native developer interested in building performant mobile
apps on both the iOS and Android platforms. You will be responsible for architecting and
building these applications, as well as coordinating with the teams responsible for other
layers of the product infrastructure. Building a product is a highly collaborative effort,
and as such, a strong team player with a commitment to perfection is required.`,
    requirements: [
      {title: 'Build pixel-perfect, buttery smooth UIs across both mobile platforms.'},
      {title: 'Leverage native APIs for deep integrations with both platforms.'},
      {title: 'Diagnose and fix bugs and performance bottlenecks for performance that feels native.'},
      {title: 'Reach out to the open-source community to encourage and help implement mission-critical software fixes—React Native moves fast and often breaks things.'},
      {title: 'Maintain code and write automated tests to ensure the product is of the highest quality.'},
      {title: 'Transition existing React web apps to React Native.'},
    ],
    technical_skills: [
      {title: 'Firm grasp of the JavaScript language and its nuances, including ES6+ syntax'},
      {title: 'Knowledge of object-oriented programming'},
      {title: 'Ability to write well-documented, clean Javascript code'},
      {title: 'Rock solid at working with third-party dependencies and debugging dependency conflicts'},
      {title: 'Familiarity with native build tools, like XCode, Gradle, Android Studio, IntelliJ'},
      {title: 'Understanding of REST APIs, the document request model, and offline storage'},
    ],
    you_should_know: [
      {title: 'Fluent written and communication skills in English'},
      {title: 'Good time-management skills'},
      {title: 'Good analytical and problem-solving skills.'},
      {title: 'The ability to quickly grasp new concepts.'},
      {title: 'Maintain code and write automated tests to ensure the product is of the highest quality.'},
    ]
  },
  {
    job_id: '2',
    job_label: 'You will develop backend components, maintain databases, and ensure responsiveness of front-end requests.',
    job_title: 'Nodejs Developer',
    job_description: `We are looking for a Node.js Developer who will be responsible for implementing web services. Your primary focus will be the development of all backend components, definition and maintenance of databases, and ensuring high performance and responsiveness to requests from the front-end.`,
    requirements: [
      {title: 'Design, build and maintain high performance, reusable, and reliable code'},
      {title: 'Strong experience of building RESTful APIs with Express or Express-based frameworks'},
      {title: 'Integration of data storage solutions which can include databases, key-value stores, blob stores, etc'},
      {title: 'Implementation of security and data protection'},
      {title: 'Unit-test the code components; document the code and the functionality while collaborating with other team members'},
      {title: 'Define code architecture decisions to support a high-performance and scalable product'},
      {title: 'Unit-test code for robustness, usability, and general reliability'},
      {title: 'Identify and correct bottlenecks and fix bugs'},
    ],
    technical_skills: [
      {title: 'Good object-oriented software design principles'},
      {title: 'Strong understanding and hands-on experience of JavaScript'},
      {title: 'Strong experience in Node.js and Express or any of the Express-based frameworks'},
      {title: 'Understanding of database schema design, modeling, and ORMs'},
      {title: 'Hands-on experience with at least 1 SQL and NoSQL DB like Postgres or MongoDB'},
      {title: 'Experience in integrating 3rd party SDKs, APIs & libraries in Express applications'},
      {title: 'Proficient understanding of Git'},
    ],
    you_should_know: [
      {title: 'Fluent written and communication skills in English'},
      {title: 'Good time-management skills'},
      {title: 'Good analytical and problem-solving skills.'},
      {title: 'The ability to quickly grasp new concepts.'},
      {title: 'Maintain code and write automated tests to ensure the product is of the highest quality.'},
    ]
  },
  {
    job_id: '3',
    job_label: 'You will plan, design, and build web applications as well as reusable components and front-end libraries.',
    job_title: 'React Developer',
    job_description: `We are looking for a React JS developer interested in building and performing on web-based applications. You will be responsible for designing and building these applications, as well as coordinating with the teams responsible for other layers of the product infrastructure. Building a product is a highly collaborative effort, and as such, a strong team player with a commitment to perfection is required.`,
    requirements: [
      {title: 'Maintain code and write automated tests to ensure the product is of the highest quality.'},
      {title: 'Planning, designing, and building web-based applications'},
      {title: 'Building reusable components and front-end libraries for future use'},
    ],
    technical_skills: [
      {title: 'Good object-oriented software design principles.'},
      {title: 'Hands-on experience with JavaScript,ES6-7,  CSS3/SASS, HTML5.'},
      {title: 'Hands-on experience with React/Redux and integrating REST APIs'},
      {title: 'Good to have skills in NodeJS/Express'},
      {title: 'Hands-on experience with Git'},
      {title: 'Working knowledge of web pack'},
      {title: 'Hands-on with at least 1 relational DB'},
      {title: 'Unit testing with jest and enzyme'},
      {title: 'Knowledge of React performance and optimization'},
    ],
    you_should_know: [
      {title: 'Fluent written and communication skills in English'},
      {title: 'Good time-management skills'},
      {title: 'Good analytical and problem-solving skills.'},
      {title: 'The ability to quickly grasp new concepts.'},
      {title: 'Maintain code and write automated tests to ensure the product is of the highest quality.'},
    ]
  },


];
