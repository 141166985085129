import React from 'react';
import {Box, Typography} from '@material-ui/core';

import DomainItem from './DomainItem';

import './Domains.css';

const Domains = () => {
  return (
    <Box className='domains-container'>
      <Box className='domains-inner-container'>
        <Box>
          <Box className='domains-text-container'>
            <span className='domains-text-line'></span>
            <Typography component='span' className='domains-text'>
              DOMAINS
            </Typography>
            <span className='domains-text-line'></span>
          </Box>
          <Box className='domains-large-text-container'>
            <Typography component='h1' className='domains-large-text'>
              Industries We Serve
            </Typography>
          </Box>
        </Box>

        <Box className='domains-cards-container'>
          <Box className='domains-cards-inner-container'>
            <DomainItem
              domainImage='fas fa-heartbeat'
              domainText='HealthCare'
            />
            <DomainItem
              domainImage='fas fa-biohazard'
              domainText='BioInformatics'
            />
            <DomainItem
              domainImage='fas fa-language'
              domainText='Translation'
            />
            <DomainItem
              domainImage='fas fa-book-reader'
              domainText='Education'
            />
            <DomainItem domainImage='fas fa-user-cog' domainText='Automotive'/>
            <DomainItem
              domainImage='fas fa-cart-plus'
              domainText='E-Commerce'
            />
            <DomainItem
              domainImage='fas fa-cogs'
              domainText='Process Automation'
            />
            <DomainItem
              domainImage='fas fa-clinic-medical'
              domainText='Pharmaceutical'
            />
            <DomainItem domainImage='fas fa-rocket' domainText='Startup'/>
            <DomainItem domainImage='fas fa-heart' domainText='Non Profit'/>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Domains;
