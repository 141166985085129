import React from 'react';
import {Box, Typography} from '@material-ui/core';
import Client from './Client';

import ARAI from './img/arai.png';
import KRIXI from './img/krixi.png';
import BITS from './img/bits.png';
import SpanPump from './img/span_pump.png';
import K11 from './img/k11.png';
import Shyena from './img/shyena.png';
import Kisan from './img/kisan.png';
import Figmd from './img/figmd.png';
import MyMentalHealthCoach from './img/my_mental_health_coach.png';
import ViaSimplex from './img/via_simplex.png';
import DataOrc from './img/data_orc.png';
import QualitySolar from './img/quality_solar.png';
import client13 from './img/client13.png';
import Extentia from './img/extentia.png';
import Catalon from './img/catalon.svg';
import Savy from './img/savy.png';
import Vernac from './img/vernac.png';
import Initiative from './img/initiative.png';
import Sivotec from './img/sivotec.png';
import Chainalysis from './img/chainalysis.png';
import DigiShield from './img/digishield.webp';
import Decipher from './img/decipher.png';
import SirenMD from './img/sirenmd.svg';
import Specibit from './img/specibit.png';
import Raiselink from './img/raiselink.png';
import Bitsmith from './img/bitsmith.png';
import Instillmotion from './img/instillmotion.png';
import YujIT from './img/yuj_it.png';
import ONDC from './img/ondc.png';
import SpiritPedia from './img/spirit_pedia.svg';

import 'swiper/swiper-bundle.css';

import './Clients.css';

const Clients = () => {
  return (
    <Box className='clients-container' id='clientsSection'>
      <Box className='clients-inner-container'>
        <Box>
          <Box className='clients-text-container'>
            <span className='clients-text-line'></span>
            <Typography component='span' className='technologies-text'>
              CLIENTS
            </Typography>
            <span className='clients-text-line'></span>
          </Box>
          <Box className='clients-large-text-container'>
            <Typography component='h1' className='clients-large-text'>
              Our Clients
            </Typography>
          </Box>
        </Box>

        <Box style={{marginTop: '1.5rem', width: '100%'}}>
          <Box className='client-cards-container'>
            <Client clientImage={ARAI}/>
            <Client clientImage={ONDC}/>
            <Client clientImage={BITS}/>
            <Client clientImage={Chainalysis}/>

            <Client clientImage={KRIXI}/>
            <Client clientImage={Shyena}/>
            <Client clientImage={SirenMD}/>
            <Client clientImage={Extentia}/>

            <Client clientImage={K11}/>
            <Client clientImage={Kisan}/>
            <Client clientImage={Figmd}/>
            <Client clientImage={Sivotec}/>

            <Client clientImage={DataOrc}/>
            <Client clientImage={Decipher}/>
            <Client clientImage={SpanPump}/>
            <Client clientImage={Raiselink}/>

            <Client clientImage={client13}/>
            <Client clientImage={YujIT}/>
            <Client clientImage={SpiritPedia}/>
            <Client clientImage={Vernac}/>

            <Client clientImage={ViaSimplex}/>
            <Client clientImage={Catalon}/>
            <Client clientImage={Savy}/>
            <Client clientImage={DigiShield}/>

            <Client clientImage={Instillmotion}/>
            <Client clientImage={QualitySolar}/>
            <Client clientImage={MyMentalHealthCoach}/>
            <Client clientImage={Initiative}/>

            <Client clientImage={Bitsmith}/>
            <Client clientImage={Specibit}/>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Clients;
