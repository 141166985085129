import React, {useState} from 'react';
import {HashLink} from 'react-router-hash-link';
import {Link} from 'react-router-dom';
import {AppBar, Box, Divider, Drawer, Hidden, IconButton, Toolbar, Typography,} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import logo from './img/logo.png';

import './Header.css';

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <Box className='header-main-container' id='headerSection'>
      <AppBar position='sticky' className='header-appbar'>
        <Box className='header-appbar-inner-container'>
          <Toolbar className='header-appbar-toolbar'>
            <Box className='header-appbar-logo-container'>
              <Link to='/'>
                <img src={logo} alt='logo' className='header-appbar-logo'/>
              </Link>
            </Box>
            <Hidden smDown>
              <Box className='header-appbar-main-menu-container'>
                <Box className='header-appbar-text-menu-container'>
                  <div>
                    <Typography
                      className='header-menu-item'
                      component={Link}
                      to='/'
                    >
                      Home
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      className='header-menu-item'
                      component={HashLink}
                      smooth
                      to='/#aboutSection'
                    >
                      About
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      className='header-menu-item'
                      component={HashLink}
                      smooth
                      to='/#serviceSection'
                    >
                      Service
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      className='header-menu-item'
                      component={HashLink}
                      smooth
                      to='/#clientsSection'
                    >
                      Clients
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      className='header-menu-item'
                      component={Link}
                      smooth
                      to='/careers'
                    >
                      Careers
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      className='header-menu-item'
                      component={HashLink}
                      smooth
                      to='/#contactSection'
                    >
                      Contact
                    </Typography>
                  </div>
                </Box>
                <Box className='header-menu-right-social-container'>
                  <Box
                    component='a'
                    href='https://www.facebook.com/wemotiveforge'
                    target='_blank'
                    style={{textDecoration: 'none', color: 'inherit'}}
                  >
                    <i className='fab fa-facebook-f header-menu-right-social-icon'></i>
                  </Box>
                  <Box
                    component='a'
                    href='https://www.linkedin.com/company/wemotiveforge'
                    target='_blank'
                    style={{textDecoration: 'none', color: 'inherit'}}
                  >
                    <i className='fab fa-linkedin header-menu-right-social-icon'></i>
                  </Box>
                  <Box
                    component='a'
                    href='https://www.instagram.com/wemotivetechforge'
                    target='_blank'
                    style={{textDecoration: 'none', color: 'inherit'}}
                  >
                    <i className='fab fa-instagram header-menu-right-social-icon'></i>
                  </Box>
                </Box>
              </Box>
            </Hidden>

            <Hidden mdUp>
              <Box className='mobile-menu-button-container'>
                <IconButton onClick={() => setOpenDrawer(true)}>
                  <MenuIcon className='mobile-menu-button'/>
                </IconButton>
              </Box>
            </Hidden>
          </Toolbar>
        </Box>
      </AppBar>

      <Drawer
        anchor='right'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        transitionDuration={400}
      >
        <Box className='mobile-drawer-conatiner'>
          <Box
            className='mobile-drawer-cancle-container'
            onClick={() => setOpenDrawer(false)}
          >
            <i className='fas fa-times mobile-drawer-cancle-button'></i>
          </Box>

          <Box>
            <div>
              <Typography
                component={Link}
                className='mobile-drawer-item'
                to='/'
                onClick={() => setOpenDrawer(false)}
              >
                Home
              </Typography>
              <Divider className='mobile-drawer-item-divider'/>
            </div>

            <div>
              <Typography
                className='mobile-drawer-item'
                component={HashLink}
                smooth
                to='/#aboutSection'
                onClick={() => setOpenDrawer(false)}
              >
                About
              </Typography>
              <Divider className='mobile-drawer-item-divider'/>
            </div>

            <div>
              <Typography
                className='mobile-drawer-item'
                component={HashLink}
                smooth
                to='/#serviceSection'
                onClick={() => setOpenDrawer(false)}
              >
                Service
              </Typography>
              <Divider className='mobile-drawer-item-divider'/>
            </div>

            <div>
              <Typography
                className='mobile-drawer-item'
                component={HashLink}
                smooth
                to='/#clientsSection'
                onClick={() => setOpenDrawer(false)}
              >
                Clients
              </Typography>
              <Divider className='mobile-drawer-item-divider'/>
            </div>

            <div>
              <Typography
                className='mobile-drawer-item'
                component={Link}
                smooth
                to='/careers'
                onClick={() => setOpenDrawer(false)}
              >
                Careers
              </Typography>
              <Divider className='mobile-drawer-item-divider'/>
            </div>

            <div>
              <Typography
                className='mobile-drawer-item'
                component={HashLink}
                smooth
                to='/#contactSection'
                onClick={() => setOpenDrawer(false)}
              >
                Contact
              </Typography>
              <Divider className='mobile-drawer-item-divider'/>
            </div>

          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;
