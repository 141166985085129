import React from 'react';
import {Box} from '@material-ui/core';

const Client = ({clientImage}) => {
  return (
    <Box className='client-card-container'>
      <img src={clientImage} alt='client' className='client-card-image '/>
    </Box>
  );
};

export default Client;
