import React from 'react';

import {Box} from '@material-ui/core';

import HeaderBanner from '../headerBanner/HeaderBanner';
import AboutUs from '../aboutUs/AboutUs';
import AboutUsCounter from '../aboutUsCounter/AboutUsCounter';
import Services from '../services/Services';
import Domains from '../domains/Domains';
// import OurProjects from '../ourProjects/OurProjects';
import WorkingProcess from '../workingProgress/WorkingProcess';
import Technologies from '../technologies/Technologies';
import OurTestimonials from '../ourTestimonials/OurTestimonials';
import Clients from '../clients/Clients';
import ContactUs from '../contactUs/ContactUs';
import Gallery from '../gallery/Gallery'
// import OurBlogs from '../ourBlogs/OurBlogs';
import './Home.css';

const Home = () => {
  return (
    <Box>
      <HeaderBanner/>
      <AboutUs/>
      <AboutUsCounter/>
      <Gallery/>
      <Services/>
      <Domains/>
      {/* <OurProjects /> */}
      <WorkingProcess/>
      <Technologies/>
      <OurTestimonials/>
      <Clients/>
      <ContactUs/>
      {/* <OurBlogs /> */}
    </Box>
  );
};

export default Home;
