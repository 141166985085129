import React from 'react';
import {Box, Typography} from '@material-ui/core';

import ServiceItem from './ServiceItem';

import service1 from './img/service1.jpg';
import service2 from './img/service2.jpg';
import service3 from './img/service3.jpg';
import service4 from './img/service4.jpg';

import './Services.css';

const Services = () => {
  return (
    <Box className='services-container' id='serviceSection'>
      <Box className='services-inner-container'>
        <Box>
          <Box className='services-what-we-do-container'>
            <span className='services-what-we-do-line'></span>
            <Typography component='span' className='services-what-we-do-text'>
              WHAT WE DO
            </Typography>
            <span className='services-what-we-do-line'></span>
          </Box>
          <Box className='services-large-text-container'>
            <Box className='services-large-text-inner-container'>
              <Typography component='h1' className='services-large-text'>
                We provide exclusive services for your business
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className='services-cards-container'>
          <ServiceItem
            serviceImage={service1}
            serviceIcon='fas fa-mobile-alt'
            serviceHeading='Mobile App Development'
            serviceText='We have a team of iOS, Android experts who focus entirely on to deliver high quality work that can streamline the business workflow and ensure better experience.'
          />
          <ServiceItem
            serviceImage={service2}
            serviceIcon='fas fa-code'
            serviceHeading='Web App development'
            serviceText='Our team of experts build creative websites and web application that actually perform & exceed their expectations to meet business objectives.'
          />
          <ServiceItem
            serviceImage={service3}
            serviceIcon='fas fa-drafting-compass'
            serviceHeading='Design and Media'
            serviceText='With the ineffable experience, our competent team build customized designs website to promote their brand effectively.'
          />
          <ServiceItem
            serviceImage={service4}
            serviceIcon='fas fa-tools'
            serviceHeading='Testing and Automation'
            serviceText='Our professional experts use advanced technologies for accomplishing the client’s requirements for testing and automation for their software.'
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Services;
