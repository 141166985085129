import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Button, Typography} from '@material-ui/core';

const BlogItem = ({
                    blogID,
                    blogAuthor,
                    blogDate,
                    blogCommentsCount,
                    blogImage,
                    blogTitle,
                    blogText,
                  }) => {
  return (
    <Box>
      <Box
        component={Link}
        to={`/blogs/${blogID}`}
        className='our-blogs-blog-image-container'
      >
        <img src={blogImage} alt='blog' className='our-blogs-blog-image'/>
      </Box>
      <Box className='our-blogs-blog-info-container'>
        <Box className='our-blogs-blog-info-inner-container'>
          <Box className='our-blogs-blog-info-item-container'>
            <i className='far fa-user our-blogs-blog-info-icon'></i>
            <Typography component='span' className='our-blogs-blog-info-text'>
              {blogAuthor}
            </Typography>
          </Box>
          <Box className='our-blogs-blog-info-item-container'>
            <i className='far fa-calendar our-blogs-blog-info-icon'></i>
            <Typography component='span' className='our-blogs-blog-info-text'>
              {blogDate}
            </Typography>
          </Box>
          <Box className='our-blogs-blog-info-item-container'>
            <i className='far fa-comments our-blogs-blog-info-icon'></i>
            <Typography component='span' className='our-blogs-blog-info-text'>
              {`(${blogCommentsCount})`}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <Box>
          <Typography
            component={Link}
            to='/'
            className='our-blogs-blog-info-heading-text'
          >
            {blogTitle}
          </Typography>
        </Box>
        <Box className='our-blogs-blog-info-body-text-container'>
          <Typography className='our-blogs-blog-info-body-text'>
            {blogText}
          </Typography>
        </Box>
        <Box>
          <Button
            className='our-blogs-blog-info-button'
            component={Link}
            to={`/blogs/${blogID}`}
          >
            Read More{' '}
            <i className='fas fa-arrow-right our-blogs-blog-info-button-icon'></i>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BlogItem;
