import React from 'react';
import {Box} from '@material-ui/core';

const TechnologyItem = ({techImage}) => {
  return (
    <Box>
      <img src={techImage} alt='tech' className='technologies-swiper-image'/>
    </Box>
  );
};

export default TechnologyItem;
