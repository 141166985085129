import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import Home from './components/home/Home';
import Blogs from './components/blogs/Blogs';
import BlogPage from './components/blogPage/BlogPage';
import CareersPage from './components/careers/CareersPage';
import CareersLandingPage from './components/careers/CareersLandingPage/careersLandingPage'
import './App.css';

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <Header/>
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route exact path='/blogs' component={Blogs}/>
        <Route exact path='/blogs/:blog_id' component={BlogPage}/>
        <Route exact path='/careers' component={CareersLandingPage}/>
        <Route exact path='/careers/:job_id' component={CareersPage}/>
      </Switch>
      <Footer/>
    </Router>
  );
};

export default App;
