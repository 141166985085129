import React from 'react';
import {HashLink} from 'react-router-hash-link';
import {Box, Button, Typography} from '@material-ui/core';

import './HeaderBanner.css';

const HeaderBanner = () => {
  return (
    <Box>
      <Box className='header-banner-container'>
        <Box className='header-banner-background'>
          <Box className='header-banner-text-container'>
            <Box className='header-banner-inner-text-container'>
              <Box className='header-banner-main-text-container'>
                <Box className='header-banner-large-text-container'>
                  <Box>
                    <Typography
                      component='h1'
                      className='header-banner-large-text'
                    >
                      SOFTWARE CONSULTING AND DEVELOPMENT FOR YOUR DIGITAL
                      SUCCESS
                    </Typography>
                  </Box>
                </Box>
                <Box className='header-banner-buttons-container'>
                  <Box>
                    <Button
                      className='header-banner-learn-more-button'
                      component={HashLink}
                      smooth
                      to='/#contactSection'
                    >
                      Contact{' '}
                      <i className='fas fa-arrow-right header-banner-buttons-icon'></i>
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      className='header-banner-how-we-work-button'
                      component={HashLink}
                      smooth
                      to='/#workingProcessSection'
                    >
                      How We Work{' '}
                      <i className='fas fa-arrow-right header-banner-buttons-icon'></i>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderBanner;
