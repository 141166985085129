import React, {useEffect, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import './CareersLandingPage.css';
import {Link, useHistory} from 'react-router-dom';

import {jobsDataForCareersPage} from '../../../utilities/jobsData/jobsData';

const CareersPage = () => {
  const [scrollPosition, setSrollPosition] = useState(0);
  const [hover, setHover] = useState(false);
  const [effectClassName, setEffectClassName] = useState();
  const handleScroll = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };

  const history = useHistory();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const goBack = () => {
    // if(window.confirm('Are you sure, you want to cancel the application?'))
    // {
    history.goBack();
    // }
  };

  const onMouseOverHandle = () => {
    setHover(true);
    setEffectClassName('job-title-on-hover');
  };

  const onMouseLeaveHandle = () => {
    setHover(false);
  };

  console.log('scrollPosition', scrollPosition);

  return (
    <>
      {scrollPosition >= 80 ? (
        <Grid
          container
          className='showed-on-scroll-grid'
          style={{backgroundColor: '#4e8fe2', color: 'white'}}
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Container maxWidth='lg'>
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='flex-start'
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Grid
                className='job-name-on-scroll'
                item
                xl={8}
                lg={8}
                md={8}
                sm={8}
                xs={8}
              >
                <Typography>All Openings</Typography>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}></Grid>
            </Grid>
          </Container>
        </Grid>
      ) : (
        <Grid
          container
          style={{backgroundColor: '#4e8fe2', color: 'white'}}
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Container maxWidth='lg'>
            <Grid
              style={{padding: '6px'}}
              container
              direction='row'
              justify='flex-end'
              alignItems='center'
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                <Typography className='header-title'>All Openings</Typography>
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}></Grid>
            </Grid>
          </Container>
        </Grid>
      )}
      {/* <Grid container   >
                    <Grid item className="header-container" xl={12} lg={12} md={12} sm={12} xs={12}>
                       <Container maxWidth="lg" >
                            <Grid container className="banner-container" >
                            <Grid className="go-back-btn" onClick={() => history.goBack()} item xl={12} lg={12} md={12} sm={12} xs={12} >
                                <Icon   className="fas fa-chevron-circle-left back-icon-height" />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                <Typography className="job-name-on-scroll" >All Openings</Typography>
                            </Grid>   
                            </Grid>
                       </Container>
                    </Grid>
                </Grid> */}

      <Box style={{minHeight: 'calc(100vh - 218px)'}}>
        <Container maxWidth='lg'>
          <Box className='job-labels'>
            {jobsDataForCareersPage.map((jobName, jobNameIndex) => {
              return (
                <Link
                  className='underline-remove'
                  to={`/careers/${jobName.job_id}`}
                >
                  <Box
                    className='job-content'
                    onMouseOver={onMouseOverHandle}
                    onMouseLeave={onMouseLeaveHandle}
                  >
                    <Typography
                      style={{textDecoration: 'none'}}
                      className='job-title'
                    >
                      {jobName.job_title}
                    </Typography>
                    <Typography className='job_label'>
                      {jobName.job_label}
                    </Typography>
                    <Typography className='view-details-text'>
                      View Details
                    </Typography>
                  </Box>
                </Link>
              );
            })}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default CareersPage;
