import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Box, Typography} from '@material-ui/core';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import {blogsDataForBlogsPage} from '../../utilities/blogsData/blogsData';

import './BlogPage.css';

const BlogPage = () => {
  const {blog_id} = useParams();

  const [blog, setBlog] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBlogData(blog_id);
  }, [blog_id]);

  const getBlogData = id => {
    const currentBlog = blogsDataForBlogsPage.filter(
      blog => blog.blog_id === id
    );

    setBlog(currentBlog[0]);
  };

  return (
    <Box className='blog-page-container'>
      <Box className='blog-page-inner-container'>
        <Box className='blog-page-blog-outer-container'>
          <Box className='blog-page-blog-container'>
            <Box>
              <Typography className='blog-page-blog-main-heading'>
                {blog.blog_title}
              </Typography>
            </Box>

            <Box className='blog-page-profile-and-share-container'>
              <Box className='our-testimonials-testimonial-user-profile-container'>
                <Box>
                  <img src={blog.blog_author_image} alt='user'/>
                </Box>
                <Box className='our-testimonials-testimonial-user-profile-text-container'>
                  <Typography className='our-testimonials-testimonial-user-name-text'>
                    {blog.blog_author}
                  </Typography>
                  <Typography className='our-testimonials-testimonial-user-profile-text'>
                    {blog.blog_date}
                  </Typography>
                </Box>
              </Box>
              <Box className='blog-page-share-container'>
                <FacebookShareButton
                  url='http://github.com'
                  quote='GitHub'
                  style={{outline: 'none', marginLeft: '0.3rem'}}
                >
                  <FacebookIcon size={32} round/>
                </FacebookShareButton>

                <LinkedinShareButton
                  url='http://github.com'
                  quote='GitHub'
                  style={{outline: 'none', marginLeft: '0.3rem'}}
                >
                  <LinkedinIcon size={32} round/>
                </LinkedinShareButton>

                <TwitterShareButton
                  url='http://github.com'
                  quote='GitHub'
                  style={{outline: 'none', marginLeft: '0.3rem'}}
                >
                  <TwitterIcon size={32} round/>
                </TwitterShareButton>

                <WhatsappShareButton
                  url='http://github.com'
                  quote='GitHub'
                  style={{outline: 'none', marginLeft: '0.3rem'}}
                >
                  <WhatsappIcon size={32} round/>
                </WhatsappShareButton>

                <TelegramShareButton
                  url='http://github.com'
                  quote='GitHub'
                  style={{outline: 'none', marginLeft: '0.3rem'}}
                >
                  <TelegramIcon size={32} round/>
                </TelegramShareButton>
              </Box>
            </Box>

            <Box style={{width: '80%', margin: 'auto', marginTop: '2rem'}}>
              <img
                src={blog.blog_image}
                alt='blog'
                style={{height: 'auto', width: '100%'}}
              />
            </Box>

            <Box style={{width: '80%', margin: 'auto', marginTop: '1rem'}}>
              <Typography className='blog-page-blog-title'>
                {blog.blog_title}
              </Typography>
              <Typography className='blog-page-blog-body'>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum
                exercitationem, itaque quibusdam praesentium debitis, quas
                nostrum magnam aut recusandae nam, nulla veniam. Repudiandae ea,
                facilis nostrum atque recusandae, iusto, cupiditate vero
                laboriosam facere sint cum! Odio molestiae aut esse, nobis
                officiis adipisci. Veritatis voluptate dicta quod nam aut
                deleniti nihil?
              </Typography>

              <Typography
                style={{
                  marginTop: '1rem',
                  fontSize: '1.2rem',
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  lineHeight: '2rem',
                }}
              >
                Comments: {`(${blog.blog_comments_count})`}
              </Typography>
              <Box className='blog-page-blog-comment-profile'>
                <Box>
                  <img src={blog.blog_author_image} alt='profile'/>
                </Box>
                <Box className='blog-page-blog-comment-profile-text-container'>
                  <Typography
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                    }}
                  >
                    Sara Niel
                  </Typography>
                  <Typography className='blog-page-blog-comment-profile-text'>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Placeat, incidunt. Lorem ipsum dolor sit amet.
                  </Typography>
                </Box>
              </Box>
              <Box className='blog-page-blog-comment-profile'>
                <Box>
                  <img src={blog.blog_author_image} alt='profile'/>
                </Box>
                <Box className='blog-page-blog-comment-profile-text-container'>
                  <Typography
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                    }}
                  >
                    Sara Niel
                  </Typography>
                  <Typography className='blog-page-blog-comment-profile-text'>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Placeat, incidunt. Lorem ipsum dolor sit amet.
                  </Typography>
                </Box>
              </Box>
              <Box className='blog-page-blog-comment-profile'>
                <Box>
                  <img src={blog.blog_author_image} alt='profile'/>
                </Box>
                <Box className='blog-page-blog-comment-profile-text-container'>
                  <Typography
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: '600',
                    }}
                  >
                    Sara Niel
                  </Typography>
                  <Typography className='blog-page-blog-comment-profile-text'>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Placeat, incidunt. Lorem ipsum dolor sit amet.
                  </Typography>
                </Box>
              </Box>

              <Box className='blog-page-blog-body-share-container'>
                <FacebookShareButton
                  url='http://github.com'
                  quote='GitHub'
                  style={{outline: 'none'}}
                >
                  <FacebookIcon size={40} round/>
                </FacebookShareButton>

                <LinkedinShareButton
                  url='http://github.com'
                  quote='GitHub'
                  style={{outline: 'none', marginLeft: '0.5rem'}}
                >
                  <LinkedinIcon size={40} round/>
                </LinkedinShareButton>

                <TwitterShareButton
                  url='http://github.com'
                  quote='GitHub'
                  style={{outline: 'none', marginLeft: '0.5rem'}}
                >
                  <TwitterIcon size={40} round/>
                </TwitterShareButton>

                <WhatsappShareButton
                  url='http://github.com'
                  quote='GitHub'
                  style={{outline: 'none', marginLeft: '0.5rem'}}
                >
                  <WhatsappIcon size={40} round/>
                </WhatsappShareButton>

                <TelegramShareButton
                  url='http://github.com'
                  quote='GitHub'
                  style={{outline: 'none', marginLeft: '0.5rem'}}
                >
                  <TelegramIcon size={40} round/>
                </TelegramShareButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BlogPage;
