import React from 'react';
import {Box, Typography} from '@material-ui/core';

const WorkingProcessItem = ({processIcon, processTitle, processBody}) => {
  return (
    <>
      <Box className='working-process-card-container'>
        <Box>
          <i className={`${processIcon} working-process-card-icon`}></i>
        </Box>
        <Box className='working-process-card-heading-text-container'>
          <Typography className='working-process-card-heading-text'>
            {processTitle}
          </Typography>
        </Box>
        <Box>
          <Typography className='working-process-card-body-text'>
            {processBody}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default WorkingProcessItem;
