import React from 'react';
import {Box, Typography} from '@material-ui/core';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const AboutUsCounterItem = ({counterIcon, counterEnd, counterLabel}) => {
  return (
    <Box className='aboutus-counter-item-container'>
      <Box className='aboutus-counter-icon-container'>
        <i className={`${counterIcon} aboutus-counter-icon`}></i>
      </Box>
      <Box component='span' className='aboutus-counter-number-container'>
        <Typography component='span' className='aboutus-counter-number'>
          <CountUp start={0} end={counterEnd}>
            {({countUpRef, start}) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef}/>
              </VisibilitySensor>
            )}
          </CountUp>
        </Typography>

        <Box component='span' className='aboutus-counter-number-plus'>
          +
        </Box>
      </Box>
      <Box>
        <Typography className='aboutus-counter-text'>{counterLabel}</Typography>
      </Box>
    </Box>
  );
};

export default AboutUsCounterItem;
