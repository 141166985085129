import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import './CareersPage.css';
import {useHistory, useParams} from 'react-router-dom';
import {jobsDataForCareersPage} from '../../utilities/jobsData/jobsData'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

const CareersPage = () => {

  const [scrollPosition, setSrollPosition] = useState(0);
  const [jobInformation, setJobInformation] = useState();
  const handleScroll = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };
  let {job_id} = useParams();
  const history = useHistory();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const goBack = () => {
    // if(window.confirm('Are you sure, you want to cancel the application?'))
    // {
    history.goBack()
    // }
  }


  return (
    <>
      {
        jobsDataForCareersPage.map((jobDetail) => {
          return (
            jobDetail.job_id === job_id ?
              <>

                <Grid container className="showed-on-scroll-grid" style={{backgroundColor: '#4e8fe2', color: 'white'}}
                      item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Container maxWidth="lg">
                    {
                      scrollPosition >= 80
                        ?
                        <Grid direction="row" justify="flex-start" alignItems="flex-start" item xl={12} lg={12} md={12}
                              sm={12} xs={12}>
                          <Grid container>
                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                              {/* <   Icon onClick={goBack} style={{fontSize:'56px',cursor:'pointer'}}  className="fas fa-chevron-circle-left" /> */}
                              <ArrowBackIosRoundedIcon onClick={goBack} style={{fontSize: '56px', cursor: 'pointer'}}/>
                            </Grid>
                            <Grid style={{transition: 'all .1011s ease !important', display: 'flex'}}
                                  className="job-name-on-scroll" item xl={8} lg={8} md={8} sm={8} xs={8}>
                              <Typography>{jobDetail.job_title}</Typography>
                            </Grid>
                            <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                              <Typography className="mail-address-details">
                                Send your profile at letstalk@wemotiveforge.com
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        :
                        <Grid container>
                          <Grid direction="row" justify="flex-start" alignItems="center"
                                style={{width: '56px', marginTop: '10px'}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid>
                              <ArrowBackIosRoundedIcon onClick={goBack} style={{fontSize: '56px', cursor: 'pointer'}}/>
                            </Grid>
                          </Grid>
                          <Grid style={{marginTop: '5px'}} direction="row" justify="flex-end" alignItems="center" item
                                xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container>
                              <Grid style={{transition: 'all 1.4s ease !important'}} className="job-name" item xl={8}
                                    lg={8} md={8} sm={8} xs={8}>
                                <Typography>{jobDetail.job_title}</Typography>
                              </Grid>
                              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                <Typography className="mail-address-details">
                                  Send your profile at letstalk@wemotiveforge.com
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                    }
                  </Container>
                </Grid>
                <Grid style={{backgroundColor: '#eaf1fb'}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Container maxWidth="lg">
                    <Grid style={{paddingTop: '36px', paddingBottom: '36px'}} item xl={12} lg={12} md={12} sm={12}
                          xs={12}>
                      <Grid container direction="row" justify="flex-start" alignItems="center" item xl={12} lg={12}
                            md={12} sm={12} xs={12}>
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
                          <Grid className="job-specification" item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography style={{fontSize: '1.5rem', fontWeight: '300'}}><FiberManualRecordIcon
                              style={{color: '#cccccc'}}/> Job Location</Typography>
                          </Grid>
                          <Grid style={{marginLeft: '30px'}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography>Pune City</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
                          <Grid className="job-specification" item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography style={{fontSize: '1.5rem', fontWeight: '300'}}><FiberManualRecordIcon
                              style={{color: '#cccccc'}}/> Job Type</Typography>
                          </Grid>
                          <Grid style={{marginLeft: '30px'}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography>Full Time</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>
                <Grid style={{marginTop: '85px'}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Container maxWidth="lg">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography className="heading-of-details" variant="h6">Job Description</Typography>
                    </Grid>
                    <Grid style={{marginTop: "15px"}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography className="text-font-for-details" style={{
                        fontSize: '13px',
                        color: 'rgb(67 67 67)'
                      }}>{jobDetail.job_description}</Typography>
                    </Grid>
                  </Container>
                </Grid>
                <Grid style={{marginTop: '85px'}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Container maxWidth="lg">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography className="heading-of-details" variant="h6">Requirements</Typography>
                    </Grid>
                    <Grid style={{marginTop: "15px"}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography style={{fontSize: '13px', color: 'rgb(67 67 67)'}}>
                        <ul style={{marginLeft: '40px'}}>
                          {
                            jobDetail.requirements.map((requirement) => {
                              return (
                                <li className="text-font-for-details"
                                    style={{marginTop: '10px'}}>{requirement.title}</li>
                              )
                            })
                          }
                        </ul>
                      </Typography>
                    </Grid>
                  </Container>
                </Grid>
                <Grid style={{marginTop: '85px'}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Container maxWidth="lg">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="h6" className="heading-of-details">Technical Skills</Typography>
                    </Grid>
                    <Grid style={{marginTop: "15px"}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography style={{fontSize: '13px', color: 'rgb(67 67 67)'}}>
                        <ul style={{marginLeft: '40px'}}>
                          {
                            jobDetail.technical_skills.map((technicalSkill) => {
                              return (
                                <li className="text-font-for-details"
                                    style={{marginTop: '10px'}}>{technicalSkill.title}</li>
                              )
                            })
                          }
                        </ul>
                      </Typography>
                    </Grid>
                  </Container>
                </Grid>
                <Grid style={{marginTop: '85px', marginBottom: '85px'}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Container maxWidth="lg">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography className="heading-of-details" variant="h6">You should have</Typography>
                    </Grid>
                    <Grid style={{marginTop: "15px"}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography style={{fontSize: '13px', color: 'rgb(67 67 67)'}}>
                        <ul style={{marginLeft: '40px'}}>
                          {
                            jobDetail.you_should_know.map((youShouldKnow) => {
                              return (
                                <li className="text-font-for-details"
                                    style={{marginTop: '10px'}}>{youShouldKnow.title}</li>
                              )
                            })
                          }
                        </ul>
                      </Typography>
                    </Grid>
                  </Container>
                </Grid>

              </>
              :
              <></>
          )
        })
      }

    </>
  )
};
export default CareersPage;
