import React from 'react';
import {Box, Typography} from '@material-ui/core';

const ServiceItem = ({
                       serviceImage,
                       serviceIcon,
                       serviceHeading,
                       serviceText,
                     }) => {
  return (
    <Box className='services-card-container'>
      <Box>
        <Box>
          <img
            src={serviceImage}
            alt='service'
            className='services-card-image'
          />
        </Box>
        <Box className='services-card-text-container'>
          <Box>
            <i className={`${serviceIcon} services-card-text-icon`}></i>
          </Box>
          <Box className='services-card-text-inner-container'>
            <Box>
              <Typography className='services-card-heading-text'>
                {serviceHeading}
              </Typography>
            </Box>
            <Box className='services-card-body-text-container'>
              <Typography className='services-card-body-text'>
                {serviceText}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceItem;
