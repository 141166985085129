import React from 'react';
import {Box} from '@material-ui/core';

import AboutUsCounterItem from './AboutUsCounterItem';

import './AboutUsCounter.css';

const AboutUsCounter = () => {
  return (
    <Box className='aboutus-counter-container'>
      <Box className='aboutus-counter-inner-container'>
        <AboutUsCounterItem
          counterIcon='fas fa-box'
          counterEnd={50}
          counterLabel='Project Completed'
        />

        <AboutUsCounterItem
          counterIcon='fas fa-heart'
          counterEnd={30}
          counterLabel='Happy Clients'
        />

        <AboutUsCounterItem
          counterIcon='fas fa-users'
          counterEnd={10}
          counterLabel='Business Partners'
        />

        <AboutUsCounterItem
          counterIcon='fas fa-trophy'
          counterEnd={5}
          counterLabel='IT Consultants'
        />
      </Box>
    </Box>
  );
};

export default AboutUsCounter;
