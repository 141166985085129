import React from 'react';
import {Box, Typography} from '@material-ui/core';
import SwiperCore, {Autoplay, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import TechnologyItem from './TechnologyItem';

import technology3 from './img/technology-3.png';
import technology4 from './img/technology-4.png';
import technology5 from './img/technology-5.png';
import technology10 from './img/technology-10.png';
import technology11 from './img/technology-11.png';
import technology12 from './img/technology-12.png';
import technology13 from './img/technology-13.png';
import technology14 from './img/technology-14.png';
import technology15 from './img/technology-15.png';
import technology16 from './img/technology-16.png';
import technology17 from './img/technology-17.png';
import technology18 from './img/technology-18.png';
import technology19 from './img/technology-19.png';
import technology20 from './img/technology-20.png';
import technology21 from './img/technology-21.png';
import technology23 from './img/technology-23.png';
import technology25 from './img/technology-25.png';
import technology26 from './img/technology-26.png';
import technology27 from './img/technology-27.png';
import technology39 from './img/technology-39.png';
import technology44 from './img/technology-44.png';
import technology48 from './img/technology-48.png';
import technology49 from './img/technology-49.png';

import 'swiper/swiper-bundle.css';

import './Technologies.css';

SwiperCore.use([Pagination, Autoplay]);

const Technologies = () => {
  const params = {
    slidesPerView: 5,
    slidesPerColumn: 3,
    spaceBetween: 15,
    slidesPerColumnFill: 'row',
    pagination: {clickable: true},
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },

    breakpoints: {
      1300: {
        slidesPerView: 5,
        slidesPerColumn: 3,
        spaceBetween: 15,
      },
      1000: {
        slidesPerView: 4,
        slidesPerColumn: 3,
        spaceBetween: 15,
      },
      730: {
        slidesPerColumn: 3,
        slidesPerView: 3,
        spaceBetween: 15,
      },
      640: {
        slidesPerColumn: 3,
        slidesPerView: 2,
        spaceBetween: 15,
      },
      320: {
        slidesPerColumn: 3,
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };

  return (
    <Box className='technologies-container'>
      <Box className='technologies-inner-container'>
        <Box>
          <Box className='technologies-text-container'>
            <span className='technologies-text-line'></span>
            <Typography component='span' className='technologies-text'>
              TECHNOLOGIES
            </Typography>
            <span className='technologies-text-line'></span>
          </Box>
          <Box className='technologies-large-text-container'>
            <Typography component='h1' className='technologies-large-text'>
              What We Use
            </Typography>
          </Box>
        </Box>
        <Box className='technologies-swiper-container'>
          <Swiper {...params} style={{paddingBottom: '2rem'}}>
            <SwiperSlide>
              <TechnologyItem techImage={technology3}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology4}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology5}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology10}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology11}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology12}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology13}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology14}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology15}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology16}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology17}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology18}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology19}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology20}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology21}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology23}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology25}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology26}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology27}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology39}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology44}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology48}/>
            </SwiperSlide>
            <SwiperSlide>
              <TechnologyItem techImage={technology49}/>
            </SwiperSlide>
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
};

export default Technologies;
