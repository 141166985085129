import blog1Image from './img/blog1.jpg';
import blog2Image from './img/blog2.jpg';
import blog3Image from './img/blog3.jpg';

import user1Image from './img/user1.png';
import user2Image from './img/user2.png';
import user3Image from './img/user3.png';

export const blogsDataForBlogsPage = [
  {
    blog_id: '1',
    blog_author: 'Soamlia',
    blog_date: '05 Aug 2019',
    blog_comments_count: '03',
    blog_image: `${blog1Image}`,
    blog_title: 'Monthly Web Development To Update React Hooks Cons',
    blog_text:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
    blog_author_image: `${user1Image}`,
  },
  {
    blog_id: '2',
    blog_author: 'Soamlia',
    blog_date: '05 Aug 2019',
    blog_comments_count: '03',
    blog_image: `${blog2Image}`,
    blog_title: 'Comparison Between WordPress & October CMS',
    blog_text:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
    blog_author_image: `${user2Image}`,
  },
  {
    blog_id: '3',
    blog_author: 'Soamlia',
    blog_date: '05 Aug 2019',
    blog_comments_count: '03',
    blog_image: `${blog3Image}`,
    blog_title: 'Exploring Latest Web Design Together With Theme',
    blog_text:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
    blog_author_image: `${user3Image}`,
  },
  {
    blog_id: '4',
    blog_author: 'Soamlia',
    blog_date: '05 Aug 2019',
    blog_comments_count: '03',
    blog_image: `${blog1Image}`,
    blog_title: 'Monthly Web Development To Update React Hooks Cons',
    blog_text:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
    blog_author_image: `${user1Image}`,
  },
  {
    blog_id: '5',
    blog_author: 'Soamlia',
    blog_date: '05 Aug 2019',
    blog_comments_count: '03',
    blog_image: `${blog2Image}`,
    blog_title: 'Comparison Between WordPress & October CMS',
    blog_text:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
    blog_author_image: `${user2Image}`,
  },
  {
    blog_id: '6',
    blog_author: 'Soamlia',
    blog_date: '05 Aug 2019',
    blog_comments_count: '03',
    blog_image: `${blog3Image}`,
    blog_title: 'Exploring Latest Web Design Together With Theme',
    blog_text:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
    blog_author_image: `${user3Image}`,
  },
  {
    blog_id: '7',
    blog_author: 'Soamlia',
    blog_date: '05 Aug 2019',
    blog_comments_count: '03',
    blog_image: `${blog1Image}`,
    blog_title: 'Monthly Web Development To Update React Hooks Cons',
    blog_text:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
    blog_author_image: `${user1Image}`,
  },
  {
    blog_id: '8',
    blog_author: 'Soamlia',
    blog_date: '05 Aug 2019',
    blog_comments_count: '03',
    blog_image: `${blog2Image}`,
    blog_title: 'Comparison Between WordPress & October CMS',
    blog_text:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
    blog_author_image: `${user2Image}`,
  },
  {
    blog_id: '9',
    blog_author: 'Soamlia',
    blog_date: '05 Aug 2019',
    blog_comments_count: '03',
    blog_image: `${blog3Image}`,
    blog_title: 'Exploring Latest Web Design Together With Theme',
    blog_text:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
    blog_author_image: `${user3Image}`,
  },
];
