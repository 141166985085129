import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Box, Typography} from '@material-ui/core';

import './Footer.css';

const Footer = () => {
  const [scrollToTop, setScrollToTop] = useState(false);

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
      setScrollToTop(false);
    }
  }, [scrollToTop]);

  return (
    <Box className='footer-container'>
      <Box className='footer-inner-container'>
        <Box className='footer-content-container'>
          <Box>
            <Typography className='footer-text'>
              &copy; Copyright {new Date().getFullYear()}{' '}
              <Link
                to='/'
                className='footer-highlight-text'
                onClick={() => {
                  setScrollToTop(true);
                }}
              >
                Via Simplex
              </Link>
              . All rights reserved.
            </Typography>
          </Box>
          <Box>
            <Typography className='footer-text'>
              Design By{' '}
              <Link
                to='/'
                className='footer-highlight-text'
                onClick={() => {
                  setScrollToTop(true);
                }}
              >
                Via Simplex
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
